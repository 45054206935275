.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.biometric-panel {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: auto;
}

.biometric-title-div {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  margin-bottom: -15px;
  gap: 10px;
}

.biometric-data-title {
  color: #4287f5;
  font-family: 'Nunito';
}

.biometric-data-title-date {
  color: #4287f5;
  font-family: 'Nunito';
}

.biometric-text-line {
  display: flex;
  flex-direction: row;
}

.biometric-icon {
  padding-top: 4px;
  width: 12px;
  height: 16px;
}

.biometric-text {
  padding: 2px;
  font-family: 'Nunito';
  margin-top: auto;
  margin-bottom: auto;
}

