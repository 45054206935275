@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Nunito&family=Ubuntu&display=swap');

.home-components {
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin-top: 5%;
  margin-right: 8%;
  margin-left: 8%;
}

.home-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

/* The text that says "Software Developer" */
.job-title {
  text-transform: uppercase;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  line-height: 27px;
  color: #4098FF;
}

.greeting {
  margin-top: 1px;
  font-family: 'IBM Plex Sans';
  font-size: 64px;
  color: #25282B;
}


.description {
  margin-top: 1px;
  font-family: 'Nunito';
  font-size: 24px;
  line-height: 150%;
  margin-right: 10%;
  color: #828282;
}

.portrait-section {
  display: flex;
  row-gap: 10px;
}

.portrait-container {
  padding: 30px;
  margin: auto;
}

.photo {
  box-shadow: inset -11.9786px -11.9786px 29.9465px rgba(255, 255, 255, 0.7), inset 11.9786px 11.9786px 29.9465px rgba(136, 160, 183, 0.3);
  padding: 15px;
  width:300px;
  border-radius: 50%;
  filter: grayscale(20%);
}

@media(max-width: 800px) {
  .home-components {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 100%;
  }
}
