@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Nunito&family=Ubuntu:ital,wght@0,400;0,500;1,500&display=swap');

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #F6FCFF; 
  height: 100%;
  margin: 0;
}

article {
  font-family: Nunito;
  font-size: 15px;
  padding: 15px;

}

.container {
  margin: 1rem;
}

.nav {
  background-color: rgba(0, 0, 0, 0.01);
  font-family: 'Raleway', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  top: 10px;
}

.site-title {
  font-size: 1.2rem;
  color: #000000;
}

.nav ul {
  font-size: 1rem; 
  padding: 0;
  margin: 0;
  margin-right: 30px;
  list-style: none;
  display: flex;
  gap: 80px;
  color: rgba(8, 0, 0, 0.69);
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

.row_set {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}


.clearfix {
  clear: both;
}

.sole_paragraph {
  margin-left:50px !important;
  margin-right:50px !important;

}

/* These rules only apply when the screen is 800px or less */
@media(max-width: 800px) {
  .row_set {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }

  .sketchfab-embed-wrapper {
    display: none;
  }

  .sole_paragraph {
    margin-left: 20px !important;
    margin-right: 20px !important;

  }

  video {
    display: block;
    margin: 0 auto;
  }


}
